<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">审核管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">课件审核</a>
        </span>
      </div>
      <div class="framePage-body">
        <!-- <div class="framePage-scroll"> -->
        <div class="ovy-a">
          <div class="flexcb">
            <h3 style="padding-bottom:20px;">课件基本信息</h3>
            <el-button v-show="stu == 'look'" size="mini" class="bgc-bv" @click="doGoBack">返回</el-button>
          </div>
          <div>
            <el-form ref="ruleForm" label-width="10rem" class="couraewareBox">
              <el-form-item label="课件名称：" class="form-cell">
                <p>{{ruleForm.kpointName}}</p>
              </el-form-item>
              <el-form-item v-show="ruleForm.trainTypeNamePath" label="培训类型：" class="form-cell">
                <p>{{ruleForm.trainTypeNamePath || ''}}</p>
              </el-form-item>
              <el-form-item v-show="ruleForm.industryNamePath" label="行业类型：" class="form-cell">
                <p>{{ruleForm.industryNamePath || ''}}</p>
              </el-form-item>
              <el-form-item v-show="ruleForm.postName" label="岗位类型：" class="form-cell">
                <p>{{ruleForm.postName || ''}}</p>
              </el-form-item>
              <el-form-item v-show="ruleForm.occupationNamePath" label="职业/工种：" class="form-cell">
                <p>{{ruleForm.occupationNamePath || ''}}</p>
              </el-form-item>
              <el-form-item v-show="ruleForm.trainLevelName" label="培训等级：" class="form-cell">
                <p>{{ruleForm.trainLevelName || ''}}</p>
              </el-form-item>
              <el-form-item label="课件学时：" class="form-cell">
                <p>{{ruleForm.kpointLessonNum || ''}}</p>
              </el-form-item>
            </el-form>
            <el-form ref="ruleForm" label-width="10rem">
              <el-form-item label="课件封面：" prop="thumbnail">
                <div class="img-el-upload" style="width:256px;">
                  <el-image v-show="ruleForm.thumbnail" :src="ruleForm.thumbnail" fit="contain"></el-image>
                </div>
              </el-form-item>
            </el-form>
          </div>

          <h3 style="padding-bottom:20px;">课件视频</h3>
          <div>
            <span style="padding-right:20px">课件视频：{{ruleForm.kpointName}}</span>
            <el-button
              type="text"
              style="padding-right:50px"
              v-if="ruleForm.showTrySeeBtn"
              @click="showVideo(ruleForm)"
            >预览</el-button>
            <el-radio-group v-model="radio" @change="RadioChange" v-show="stu!='look'">
              <el-radio label="30">通过</el-radio>
              <el-radio label="40">不通过</el-radio>
            </el-radio-group>
            <div style="display:flex;padding-top:.5rem;" v-show="radioShow">
              <el-input
                type="textarea"
                placeholder="请输入不通过原因"
                v-model="textarea"
                ref="textarea"
                maxlength="100"
                show-word-limit
              />
            </div>
          </div>
          <div style="padding:15px 0">
            <span style="padding-right:50px">授课讲师：{{ruleForm.teacherPathName || '无讲师'}}</span>
          </div>
          <div>
            <span style="padding-right:50px">课后习题：{{ruleForm.paperName || '无课后习题'}}</span>
            <el-button
              type="text"
              style="padding-right:50px"
              @click="showPaper(ruleForm.paperId)"
              :disabled="!ruleForm.paperName"
            >预览</el-button>
          </div>
          <h3 style="padding:30px 0;">审批记录</h3>

          <el-table
            ref="multipleTable"
            :data="aduitLogsList"
            size="small"
            tooltip-effect="dark"
            :header-cell-style="tableHeader"
          >
            <el-table-column
              label="操作日期"
              align="center"
              :show-overflow-tooltip="true"
              prop="createTime"
            >
              <template slot-scope="scope">{{ scope.row.createTime | moment }}</template>
            </el-table-column>
            <el-table-column
              label="操作人"
              align="center"
              :show-overflow-tooltip="true"
              prop="fullname"
            />
            <el-table-column
              label="审批状态"
              align="center"
              :show-overflow-tooltip="true"
              prop="trainTypeNamePath"
            >
              <template slot-scope="scope">{{ $setDictionary("AUDITSTATE", scope.row.auditState)}}</template>
            </el-table-column>
            <el-table-column
              label="审批备注"
              align="center"
              :show-overflow-tooltip="true"
              prop="auditReason"
            />
            <Empty slot="empty" />
          </el-table>
        </div>

        <div
          style="display: flex; justify-content: center;left: 50%;right: 50%;"
          v-show="stu != 'look'"
        >
          <el-button @click="back()" class="bgc-bv">取消</el-button>
          <el-button @click="getSure" class="bgc-bv">确定</el-button>
        </div>
      </div>
    </div>
    <PaperShow ref="PaperShow" />
  </div>
</template>
<script>
let id = 1000;
import Empty from "@/components/Empty.vue";
import List from "@/mixins/List";
import PaperShow from "@/views/resourse/popup/PaperShow";
export default {
  name:'CoursewareReviewDetails',
  mixins: [List],
  components: {
    PaperShow
  },
  data() {
    return {
      aduitLogsList:[],//审核记录
      data: [],
      defaultProps: {
        id: "courseKpointId",
        children: "children",
        label: "courseKpointId"
      },
      ruleForm: {},
      radio: true,
      kpointId: "",
      radioShow: false,
      textarea: "",
      activeName: this.$route.query.activeName,
      stu: ""
    };
  },
  created() {
    this.kpointId = this.$route.query.kpointId;
    this.stu = this.$route.query.stu;
    this.projectId = sessionStorage.getItem("projectId");
    this.getDetail(this.kpointId);
  },
  mounted() {},
  methods: {
    doGoBack() {
      this.$router.push({
        path: "/web/CoursewareReview",
        query: {
          activeName: "second"
        }
      });
    },
    /**
     *
     */
    getDetail(kpointId) {
      this.$post("/run/courseware/queryCourseWareDetail", {
        kpointId
      }).then(result => {
        const data = result.data || {};
        if (result.status === "0") {
          this.aduitLogsList=data.aduitLogs
          this.ruleForm = {
            thumbnail: data.kpointLogo,
            trainTypeNamePath: data.trainTypeNamePath,
            postName: data.postName,
            industryNamePath: data.industryNamePath,
            occupationNamePath: data.occupationNamePath,
            trainLevelName: data.trainLevelName,
            kpointLessonNum: data.kpointLessonNum,
            paperName: data.paperName,
            paperId: data.paperId,
            kpointName: data.kpointName,
            kpointVideoId: data.kpointVideoId,
            kpointId: data.kpointId,
            teacherPathName: data.teacherPathName,
            auditState: data.auditState,
            kpointVideoUrl:data.kpointVideoUrl,

            kpointSource:data.kpointSource,
            rrsTryUrl:data.rrsTryUrl,
            showTrySeeBtn:data.showTrySeeBtn,

          };
          this.radio = data.auditState;
          this.textarea = data.auditReason;
          if (this.radio == "40") {
            this.radioShow = true;
          } else {
            this.radioShow = false;
          }
        }
      });
    },
    RadioChange(val) {
       this.radio = val
      if (val == "40") {
        this.radioShow = true;
      } else {
        this.radioShow = false;
      }
    },
    showPaper(data) {
      this.$refs.PaperShow.showPopUp(data); // 打开弹窗预览试题
    },
    /**
     * 保存
     */
    getSure() {
      if (this.textarea == "") {
        this.$message({
          message: "请输入课程不通过原因",
          type: "warning"
        });
        return false;
      }
      let parmar = {
        kpointId: this.kpointId,
        auditState: this.radio,
        currentAuditState: this.ruleForm.auditState
      };
      if (this.radio == "40") {
         console.log(this.$refs.textarea.value);
        if (this.$refs.textarea.value == undefined) {
          this.$message({
            message: "请输入课程不通过原因",
            type: "error"
          });
          return false;
        } else {
          if (this.$refs.textarea.value.length < 5) {
          this.$message({
            message: "请输入不少于5个字的课程不通过原因",
            type: "error"
          });
          return false;
        }
        }
        if (this.textarea) {
          parmar.auditReason = this.textarea;
        }
      }
      this.$post("/run/courseware/auditCourseWare", parmar)
        .then(ret => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success"
            });
            setTimeout(() => {
              this.$router.push({
                path: "/web/CoursewareReview",
                query: {
                  activeName: this.activeName,
                  refresh: true
                }
              });
            }, 300);
          }
        })
        .catch(err => {
          return;
        });
    },
    back() {
      this.$router.push({
        path: "/web/CoursewareReview",
        query: {
          activeName: this.activeName
        }
      });
    },
    showVideo(data) {
      this.$store.dispatch("handleVideoUrl", {
        kpointVideoId: data.kpointVideoId,
        kpointName: this.ruleForm.kpointName,
        kpointId: this.ruleForm.kpointId,
        kpointVideoUrl:data.kpointVideoUrl,
        kpointSource:data.kpointSource
      });
    }
  }
};
</script>
<style lang="less">
.couraewareBox {
  display: flex;
  flex-wrap: wrap;
  .form-cell {
    width: 50%;
  }
}
.imgbox {
  .el-form-item__content {
    width: 256px !important;
    height: 156px;
  }
}
.el-textarea {
  .el-textarea__inner {
    min-height: 100px;
    resize: none;
  }
}
</style>
<style lang="less" scoped>
.el-textarea {
  .el-textarea__inner {
    height: 10rem !important;
  }
}
.Basicinformation {
  display: flex;
  flex-wrap: wrap;
  > div {
    width: 50%;
    padding: 10px 0;
  }
  .information {
    display: flex;
    flex-wrap: wrap;
    .information-item {
      width: 50%;
    }
  }
}
.lessonNum {
  display: flex;
  padding: 1rem;
  span {
    padding: 0 1rem;
  }
}
.lessNumcount {
  display: flex;
  align-items: center;
  padding: 0 2rem;
  span {
    width: 10rem;
  }
}
.bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-tree-node {
  .el-form-item {
    margin-bottom: 0px;
  }
}
</style>